const voteResultList = [
	"批准",
	"不批准",
	"修改后同意",
	"修改后再审",
	"暂停或者终止研究"
]
// 眼防所 咨询分类
const categoryList = [
	{
		label: "咨询申请",
		value: "0"
	},
	{
		label: "检查平台使用",
		value: "1"
	},
	{
		label: "CRC服务",
		value: "2"
	},
	{
		label: "生物样本库",
		value: "3"
	}
]
// 经费管理模块 机构 入账信息
const inAccountInfo = [
	{
		label: "立项审查质控费",
		value: 1
	},
	{
		label: "临床试验经费首笔款",
		value: 2
	},
	{
		label: "临床试验经费第二笔款",
		value: 3
	},
	{
		label: "临床试验经费第三笔款",
		value: 4
	},
	{
		label: "临床试验经费第四笔款",
		value: 5
	},
	{
		label: "临床试验经费末次款",
		value: 6
	},
	{
		label: "其他：请注明经费具体用途",
		value: 7
	}
]
// 环境 经费管理模块 伦理 入账信息
const szEthicalInAccountInfo = [
	{
		label: "初始审查",
		value: 1
	},
	{
		label: "复审",
		value: 2
	},
	{
		label: "修正案会审",
		value: 3
	},
	{
		label: "SAE会审",
		value: 4
	},
	{
		label: "年审会审",
		value: 5
	},
	{
		label: "方案违背会审",
		value: 6
	},
	{
		label: "结题快审",
		value: 7
	}
]
// 非环境 经费管理模块 伦理 入账信息
const ethicalInAccountInfo = [
	{
		label: "伦理初始审查费",
		value: 1
	},
	{
		label: "伦理跟踪审查费",
		value: 2
	},
	{
		label: "伦理结题审查费",
		value: 3
	},
	{
		label: "其他：请注明经费具体用途",
		value: 4
	}
]
// 伦理上会 会议状态
const statusList = [
	{
		value: 1,
		label: "未开始"
	},
	{
		value: 2,
		label: "进行中"
	},
	{
		value: 3,
		label: "已结束"
	}
]
export default {
	voteResultList,
	categoryList,
	inAccountInfo,
	ethicalInAccountInfo,
	szEthicalInAccountInfo,
	statusList
}
